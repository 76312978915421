<!-- =========================================================================================
  File Name: clientView.vue
  Description: Fleet View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/Fleet/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-view">
    <vs-alert
      color="danger"
      v-bind:title="$t('CompanyNotFound')"
      :active.sync="Company_not_found"
    >
      <span>record with ID : {{ $route.params.Id }} not found. </span>
      <span>
        <span>Check </span
        ><router-link
          :to="{ name: 'FleetCompaniesDetails' }"
          class="text-inherit underline"
          >All Clients</router-link
        >
      </span>
    </vs-alert>

    <div id="user-data" v-if="FormData">
      <vx-card v-bind:title="$t('CompanyInformation')" class="mb-base">
        <div class="vx-row">
          <!-- Avatar Col -->
          <!-- <img :src="FormData.CompanyLogo" /> -->
          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">{{ $t("Id") }}</td>
                <td>{{ FormData.id }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("CompanyName") }}</td>
                <td>{{ FormData.CompanyName }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("PhoneNumber") }}</td>
                <td>
                  <span :dir="$vs.rtl ? 'ltr' : 'ltr'">{{
                    FormData.PhoneNo
                  }}</span>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("Email") }}</td>
                <td>{{ FormData.Email }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("Balance") }}</td>
                <td>
                  {{ CompanyWalletBalance | numFormat("###,##0.00") }}
                  {{ $t(FormData.countrycode + "_Currency") }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("CustomerId") }}</td>
                <td>{{ FormData.CustomerId }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("Status") }}</td>
                <td style="width: 75vw">
                  <v-select
                    style="padding: 0px !important"
                    :reduce="Code => Code.Code"
                    class="w-full"
                    :options="this.$options.AccountStatusJson"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    :get-option-label="option => $t(option.Code)"
                    v-model="FormData.Status"
                    @input="confirmChangeStatus"
                  >
                  </v-select>
                </td>



                <!-- <td>{{ $t(FormData.Status) }}</td> -->
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("TaxID") }}</td>
                <td>
                  {{ FormData.TaxID }}
                </td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">{{ $t("Country") }}</td>
                <td>{{ FormData.country }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("CountryCode") }}</td>
                <td>{{ FormData.countrycode }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("City") }}</td>
                <td>{{ FormData.City }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("Address") }}</td>
                <td>{{ FormData.Address }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("State") }}</td>
                <td>{{ FormData.State }}</td>
              </tr>

              <tr>
                <td class="font-semibold">{{ $t("RegistrationDate") }}</td>
                <td>
                  {{
                    FormData.RegistrationDate | formatDate("yyyy/MM/dd HH:mm")
                  }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">
                  {{ $t("CommercialRegistrationID") }}
                </td>
                <td>
                  {{ FormData.CommercialRegistrationID }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("ZibCode") }}</td>
                <td>
                  {{ FormData.ZibCode }}
                </td>
              </tr>
              <!--   <tr>
                <td class="font-semibold">{{ $t("WalletCountryCode") }}</td>
                <td>
                  {{ FormData.WalletCountryCode }}
                </td>
              </tr> -->
            </table>
          </div>
          <!-- Buttons - Col 2 -->
          <div class="flex flex-wrap m-4">
            <div class="btn-group mb-2 mt-2 ">
              <router-link
                :to="{
                  name: 'EditCompany',
                  params: {
                    data: {
                      Id: FormData.id,
                      CountryCode: FormData.CountryCode,
                      PhoneNumber: FormData.PhoneNumber
                    }
                  }
                }"
              >
                <vs-button icon-pack="feather" icon="icon-edit" class="mr-4">
                  {{ $t("Edit") }}</vs-button
                >
              </router-link>
            </div>
            <div class="btn-group mb-2 mt-2 ">
              <router-link
                :to="{
                  name: 'FleetCompanyUsers',
                  params: { Id: FormData.id }
                }"
              >
                <vs-button icon-pack="feather" class="mr-4">
                  {{ $t("CompanyUsers") }}</vs-button
                >
              </router-link>
            </div>
            <div class="btn-group mb-2 mt-2 ">
              <router-link
                :to="{
                  name: 'CompanyDrivers',
                  params: { Id: FormData.id }
                }"
              >
                <vs-button icon-pack="feather" class="mr-4">
                  {{ $t("CompanyDrivers") }}</vs-button
                >
              </router-link>
            </div>
            <div class="btn-group mb-2 mt-2 ">
              <router-link
                :to="{
                  name: 'CompanyBankAccountInformation',
                  params: { Id: FormData.id }
                }"
              >
                <vs-button icon-pack="feather" class="mr-4">
                  {{ $t("BankAccountInfo") }}</vs-button
                >
              </router-link>
            </div>
            <div class="btn-group mb-2 mt-2 ">
              <vs-button
                icon-pack="feather"
                class="mr-4"
                @click="confirmApplyBank"
                icon="icon-edit"
                >{{ $t("ApplyBankInfoToAllDrivers") }}</vs-button
              >
            </div>

            <div class="btn-group mb-2 mt-2 ">
              <router-link
                :to="{
                  name: 'AddCompanyDriver',
                  params: { Id: FormData.id }
                }"
              >
                <vs-button icon-pack="feather" class="mr-4">
                  {{ $t("AddDriver") }}</vs-button
                >
              </router-link>
            </div>

            <div class="btn-group mb-2 mt-2 ">
              <router-link
                :to="{
                  name: 'CompanyBiadjosHistory',
                  params: { Id: FormData.id }
                }"
              >
                <vs-button icon-pack="feather" class="mr-4">
                  {{ $t("BiadjosHistory") }}</vs-button
                >
              </router-link>
            </div>

            <div class="btn-group mb-2 mt-2 ">
              <router-link
                :to="{
                  name: 'CompanyScheduledBiadjos',
                  params: { Id: FormData.id }
                }"
              >
                <vs-button icon-pack="feather" class="mr-4">
                  {{ $t("ScheduledBiadjos") }}</vs-button
                >
              </router-link>
            </div>

            <div class="btn-group mb-2 mt-2 ">
              <router-link
                :to="{
                  name: 'CompanySubmitedOrdersAndOffers',
                  params: { Id: FormData.id, CountryCode: FormData.countrycode }
                }"
              >
                <vs-button icon-pack="feather" class="mr-4">
                  {{ $t("ViewSubmittedOrders") }}</vs-button
                >
              </router-link>
            </div>

            <div class="btn-group mb-2 mt-2 ">
              <router-link
                :to="{
                  name: 'CompanyTransactionHistory',
                  params: { Id: FormData.id }
                }"
              >
                <vs-button icon-pack="feather" class="mr-4">
                  {{ $t("TransactionsHistory") }}</vs-button
                >
              </router-link>
            </div>

            <div class="btn-group mb-2 mt-2 ">
              <router-link
                :to="{
                  name: 'FleetCompanyWalletBalance',
                  params: { Id: FormData.id, CC: FormData.countrycode }
                }"
              >
                <vs-button icon-pack="feather" class="mr-4">
                  {{ $t("CompanyWalletBalance") }}</vs-button
                >
              </router-link>
            </div>

            <div class="btn-group mb-2 mt-2 ">
              <vs-button
                type="border"
                color="warning"
                class="mr-4"
                icon-pack="feather"
                @click="Back"
                >{{ $t("Back") }}</vs-button
              >
            </div>
          </div>
        </div>
      </vx-card>
      <vx-card  v-bind:title="$t('CompanyVehicleInformation')">


          <div >
            <vs-table
            ref="table"
            :data="VehicleData"
          >
            <template  slot="thead">
              <vs-th sort-key="LicensePlate">{{ $t("LicensePlate") }}</vs-th>
              <vs-th sort-key="VehiclesType">{{ $t("VehiclesType") }}</vs-th>
              <vs-th sort-key="VehiclesBrand">{{ $t("VehiclesBrand") }}</vs-th>
              <vs-th sort-key="Driver">{{ $t("Driver") }}</vs-th>

              <vs-th sort-key="Status">{{ $t("Status") }}</vs-th>
              <vs-th sort-key="Actions">{{ $t("Actions") }}</vs-th>

            </template>

            <template slot-scope="{ data }">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      {{ $t(tr.licensePlate) }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <p class="product-category">
                      {{ $t(tr.typeName) }}
                    </p>
                  </vs-td>
                  <vs-td>
                      <p>{{ $t(tr.brandsName) }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-category">
                      {{ $t(tr.driverName) }}
                    </p>
                  </vs-td>


                  <vs-td>
                  <p class="product-category">
                      {{ $t(tr.status) }}
                    </p>

                    </vs-td>
                  <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editVehicleDetails(tr)" />
                    <vs-td>


                </vs-td>


                </vs-tr>
              </tbody>
            </template>
          </vs-table>
          </div>





      </vx-card>
      <br>

      <div id="user-data" v-if="IsVehicleDetails==true">

        <vx-card v-bind:title="$t('CompanyVehicleInformation')" class="mb-base">
          <x-icon size="1.5x" style="margin-right: 99%; color: red;" class="custom-class" @click.stop="closeVehicleDetails()"></x-icon>
        <div class="vx-row">

          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">{{ $t("LicensePlate") }} : </td>
                <td>{{ VehicleDetails.licensePlate }} </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("Status") }}</td>
                <td style="width: 200px">
                  <v-select
                    style="padding: 0px !important"
                    :reduce="Code => Code.Code"
                    class="w-full"
                    :options="this.$options.AccountStatusJson"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    :get-option-label="option => $t(option.Code)"
                    v-model="VehicleDetails.status"
                    @input="confirmVehicleChangeStatus"
                  >
                  </v-select>
                </td>

              </tr>
              <tr>
                <td class="font-semibold">{{ $t("VehiclesBrand") }}</td>
                <td>{{ VehicleDetails.brandsName }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("VehiclesColor") }}</td>
                <td>{{ VehicleDetails.colorName }}</td>
              </tr>

              <tr>
                <td class="font-semibold">{{ $t("VehicleLength") }}</td>
                <td>{{ $t(VehicleDetails.vehicleLength) }} {{ $t("Meter") }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("licensePlateFront") }}</td>
                <td>
                  <div class="img-container mb-4" id="avatar-col">
              <img :src="VehicleDetails.licensePlateImgFront" class="rounded w-full" />
            </div>
          </td>
              </tr>
            </table>
          </div>

          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">{{ $t("CarRegistrationExp") }} : </td>
                <td>
                  {{
                    VehicleDetails.carLicenseExp | formatDate("yyyy/MM/dd HH:mm")
                  }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("VehiclesType") }}</td>
                <td>{{ VehicleDetails.typeName }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("VehiclesWeight") }}</td>
                <td>{{ VehicleDetails.vehicleWeight }}</td>
              </tr>

              <tr>
                <td class="font-semibold">{{ $t("VehicleWidth") }}</td>
                <td>{{ VehicleDetails.vehicleWidth }} {{ $t("Meter") }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("VehicleHeight") }}</td>
                <td>{{ VehicleDetails.vehicleHeight }} {{ $t("Meter") }}</td>
              </tr>
              <tr>
                <td class="font-semibold">{{ $t("licensePlateBack") }}</td>
                <td>
                  <div class="img-container mb-4" id="avatar-col">
              <img :src="VehicleDetails.licensePlateImgBack" class="rounded w-full" />
            </div>
          </td>
              </tr>
            </table>
          </div>


        </div>
        <center>

          <div  id="avatar-col">
            <div  class="vx-row">
              <p class="font-semibold" style="width: 200px;">{{ $t("CategoryImageUrl") }}</p>
              <br>
              <img :src="VehicleDetails.imagURL" class="rounded w-full" />
            </div>
          </div>
        </center>
      </vx-card>

      <vs-popup fullscreen :active.sync="popupCountryJson">
        <div class="vx-row">
          <vs-input
            class="w-full m-4"
            placeholder="Country Search"
            v-model="search"
          />
        </div>
        <div class="vx-row w-full">
          <div
            class="vx-col w-1/2 cursor-pointer hover:text-dark hover:bg-primary rounded-full"
            v-for="(item, index) in filteredList"
            :key="index"
          >
            <span @click="FCMconfirmation('changeCountry', item)">
              <img
                class=" w-8 h-8"
                :src="require(`@/assets/flags/${item.asset}`)"
              />
              <span class="align-buttom m-1 text-lg"> {{ item.name }} </span>
            </span>
          </div>
        </div>
      </vs-popup>

      <vs-popup :active.sync="popupNavigateToMob">
        <div class="vx-row">
          <div
            class="vx-col w-full cursor-pointer hover:text-dark hover:bg-primary rounded-full"
            v-for="(item, index) in NavigateToMobList"
            :key="index"
          >
            <div @click="FCMconfirmation('GoTo', item)" class="mb-2">
              <span class="align-buttom m-2 text-lg"> {{ $t(item) }} </span>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-popup :active.sync="popupNavigateToWeb">
        <div class="vx-row">
          <div
            class="vx-col w-full cursor-pointer hover:text-dark hover:bg-primary rounded-full"
            v-for="(item, index) in NavigateToWebList"
            :key="index"
          >
            <div @click="FCMconfirmation('GoTo', item)" class="mb-2">
              <span class="align-buttom m-2 text-lg"> {{ $t(item) }} </span>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>


      <fleetCompanyPermissions
        :FormData="FormData"
        @Refresh="Refresh"
      ></fleetCompanyPermissions>
    </div>
    <vx-card>
      <div class="vx-row ">
        <div class="vx-col scroll-area scroll" id="ScrollToEnd">
          <VxTimeline v-if="IsFleetNotes" :data="FleetNotes"></VxTimeline>
          <span v-else class="text-warning text-lg">{{
            $t("NoNotesAvailable")
          }}</span>
        </div>
      </div>
      <div>
        <vs-row>
          <div class="vx-col sm:w-2/3 mb-2">
            <vs-textarea
              class="w-full"
              color="primary"
              label-placeholder="Note"
              v-model="NoteModel.Notice"
            />
          </div>

          <div class="vx-col sm:w-1/3 ">
            <vs-button
              class="mt-4 ml-2"
              radius
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-check"
              :disabled="NoteModel.Notice == ''"
              @click="AddFleetNote"
            ></vs-button>
          </div>
        </vs-row>
      </div>
    </vx-card>
  </div>
</template>
<script>
import moduleFleetCompanies from "@/store/fleetCompanies/moduleFleetCompanies.js";
import fleetCompanyPermissions from "@/views/fleetCompanies/fleetCompanyDetailsPermissions.vue";
import VxTimeline from "@/components/timeline/VxTimeline.vue";
import AccountStatus_JSON from "./json/accountStatus.json";
import { XIcon } from 'vue-feather-icons'

export default {
  AccountStatusJson: AccountStatus_JSON,
  data() {
    return {
      CompanyWalletBalance: "",
      OldAccountStatus: "",
      OldVehiclesStatus: "",
      FormData: {},
      VehicleDetails: {},
      Company_not_found: false,
      NoteModel: {
        AdminUserId: "",
        CompanyID: "",
        Notice: ""
      },
      IsFleetNotes: false,
      IsVehicleDetails:false,
      FleetNotes: [
        {
          time: null,
          desc: null,
          title: null,
          color: "primary",
          icon: "PlusIcon"
        }
      ]
    };
  },
  components: {
    fleetCompanyPermissions,
    VxTimeline,
    XIcon
  },
  methods: {

    editVehicleDetails(data){
      this.VehicleDetails= data;
      this.IsVehicleDetails=true;

    },
    closeVehicleDetails(){

      this.IsVehicleDetails=false;

    },
    Refresh() {
      this.fetchData();
      this.fetchVehicleData();
    },
    Back() {
      this.$router.go(-1);
    },
    confirmApplyBank() {
      this.$vs.dialog({
        type: "confirm",
        color: "#27ABBE",
        title: this.$t("ConfirmChange"),
        text: this.$t("YouAreAboutToUpdateBankInfoForAllDrivers"),
        cancelText: this.$t("Cancel"),
        accept: this.ApplyBankInfoToAllDrivers,
        acceptText: this.$t("Save")
      });
    },
    ApplyBankInfoToAllDrivers() {
      this.$vs.loading();
      let JsonObj = {};
      JsonObj.CompanyId = this.$route.params.Id;
      this.$store
        .dispatch("CompanyList/applyBankInfoToAllDrivers", JsonObj)
        .then(response => {
          if (response.status == 200) {
            this.$vs.loading.close();
            window.showSuccess();
          }
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
          window.showError();
        });
    },

    CancelAlert() {
      this.FormData.AccountStatus = this.OldAccountStatus;
    },
    fetchData() {
      this.$vs.loading();
      var company = {};
      company.CompanyId = this.$route.params.Id;
      this.$store
        .dispatch("CompanyList/GetComapneyDetails", company)
        .then(res => {
          if (res.status == 200) {
            this.$vs.loading.close();
            this.FormData = res.data;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
          window.showError();
        });

      this.$store
        .dispatch("CompanyList/CompanyWalletBalance", company)
        .then(response => {
          if (response.status == 200) {
            this.CompanyWalletBalance = response.data;
            this.$vs.loading.close();
          }
          this.$vs.loading.close();
        });
        this.AdminUserId = JSON.parse(localStorage.getItem("userInfo")).Id;
    },
    fetchVehicleData() {
      this.$vs.loading();
      var company = {};
      company.CompanyId = this.$route.params.Id;
      this.$store
        .dispatch("CompanyList/GetVehicleByComapney", company)
        .then(res => {
          if (res.status == 200) {
            this.$vs.loading.close();
            this.VehicleData = res.data;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
          window.showError();
        });
        this.AdminUserId = JSON.parse(localStorage.getItem("userInfo")).Id;
    },
    getFleetNotes() {
      this.$vs.loading();
      this.FleetNotes = [];
      var Fleet = {};
      Fleet.CompanyID = this.$route.params.Id;
      this.$store
        .dispatch("CompanyList/GetNoticeForFleet", Fleet)
        .then(response => {
          if (response.data.length > 0) {
            this.FleetNotes = response.data;
            this.IsFleetNotes = true;
            setTimeout(() => {
              var div = document.getElementById("ScrollToEnd");
              div.scrollTop = div.scrollHeight - div.clientHeight;
            }, 1000);
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    confirmChangeStatus() {
      this.$vs.dialog({
        type: "confirm",
        color: "#27ABBE",
        title: this.$t("ConfirmChange"),
        text: this.$t("YouAreAboutToChangeDriverStatus"),
        accept: this.ChangeRecord,
        acceptText: this.$t("Save"),
        cancelText: this.$t("Cancel"),

        cancel: this.CancelAlert
      });
    },
    CancelAlert() {
      this.FormData.Status = this.OldAccountStatus;
    },
    ChangeRecord() {
      if (this.FormData.Status == null) {
        this.FormData.Status = this.OldAccountStatus;
        return;
      }

      //this.FormData.Id = this.$route.params.Id;

      var driver = {};
      driver.Id = this.$route.params.Id;
      driver.AccountStatus = this.FormData.Status;
      driver.AdminUserId = this.AdminUserId;
      this.$store
        .dispatch("CompanyList/UpdateDriverAccountStatus", driver)
        .then(res => {
          if (res.status === 200) {
            this.OldAccountStatus = this.FormData.Status;
            window.showSuccess();
          }
        })
        .catch(err => {
          window.showError();
        });
    },

    confirmVehicleChangeStatus() {
      this.$vs.dialog({
        type: "confirm",
        color: "#27ABBE",
        title: this.$t("ConfirmChange"),
        text: this.$t("YouAreAboutToChangeVehicleStatus"),
        accept: this.ChangeVehicleRecord,
        acceptText: this.$t("Save"),
        cancelText: this.$t("Cancel"),

        cancel: this.CancelVehicleAlert
      });
    },
    CancelVehicleAlert() {
      this.VehicleDetails.status = this.OldVehiclesStatus;
    },
    ChangeVehicleRecord() {
      debugger;
      if (this.VehicleDetails.status == null) {
        this.VehicleDetails.status = this.OldVehiclesStatus;
        return;
      }


      var Vehicle = {};
      Vehicle.Id = this.VehicleDetails.id;
      Vehicle.VehicleStatus = this.VehicleDetails.status;
      Vehicle.AdminUserId = this.AdminUserId;
      this.$store
        .dispatch("CompanyList/UpdateVehicleStatus", Vehicle)
        .then(res => {
          if (res.status === 200) {
            this.OldVehiclesStatus = this.VehicleDetails.status;
            window.showSuccess();
          }
        })
        .catch(err => {
          window.showError();
        });
    },

    AddFleetNote() {
      this.NoteModel.AdminUserId = this.userInfo.Id;
      this.NoteModel.CompanyID = this.$route.params.Id;
      this.$vs.loading();
      this.$store
        .dispatch("CompanyList/AddNoticeToFleet", this.NoteModel)
        .then(response => {
          if (response.data == true) {
            this.NoteModel.Notice = "";
            this.getFleetNotes();
            window.showSuccessAddNote();
            setTimeout(() => {
              var div = document.getElementById("ScrollToEnd");
              div.scrollTop = div.scrollHeight - div.clientHeight;
            }, 1000);
            this.$vs.loading.close();
          } else {
            window;
            this.$vs.loading.close();
            window.showError();
          }
        });
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!moduleFleetCompanies.isRegistered) {
      this.$store.registerModule("CompanyList", moduleFleetCompanies);
      moduleFleetCompanies.isRegistered = true;
    }
    this.fetchData();
    this.fetchVehicleData();
    this.getFleetNotes();
  }
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }

@media screen and (min-width: 1201px) and (max-width: 1211px),
  only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }
}
</style>
